import React, { FC, ReactElement } from 'react';
import { Route, BrowserRouter as Router, Navigate, Routes } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { history } from '../../core/modules';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import queryString from 'query-string';
// Components
import ToastContainer from './toastProvider';
import ResponsiveCenterContainer from '../../components/layout/responsiveCenterContainer';
import lazyWrapper from '../../components/lazyWrapper';
import LogoView from '../../components/logoView';
// Ressources
import { paths } from './paths';
import configs from '../../config';
import asyncStorageApi, {
    KEY_WV_APP_FROM,
    KEY_WV_MOBILE_VERSION,
    KEY_WV_STATUS_BAR_HEIGHT,
    saveDeepLinkActions,
} from '../../localStorageAPI';
import { DeepLink } from '../../core/apiTypes';

//= ====================================//
//= ============== Pages ===============//
//= ====================================//

const TestPage = React.lazy(() => lazyWrapper(() => import('../routes/test')) as any);
const HomePage = React.lazy(() => lazyWrapper(() => import('../routes/home')) as any);
const ConcurPage = React.lazy(() => lazyWrapper(() => import('../routes/concur')) as any);

const CaptchaPage = React.lazy(() => lazyWrapper(() => import('../routes/captcha')) as any);

// const DetectPage = React.lazy(() => lazyWrapper(() => import('../routes/detect')) as any);

const OtpPage = React.lazy(() => lazyWrapper(() => import('../routes/otp')) as any);
const AskPhonePage = React.lazy(() => lazyWrapper(() => import('../routes/askPhone')) as any);
const VerifyPhonePage = React.lazy(() => lazyWrapper(() => import('../routes/verifyPhone')) as any);
const PasswordPage = React.lazy(() => lazyWrapper(() => import('../routes/password')) as any);
const SetPasswordPage = React.lazy(() => lazyWrapper(() => import('../routes/setPassword')) as any);
const ActivatePage = React.lazy(() => lazyWrapper(() => import('../routes/activate')) as any);
const ActivateEmailPage = React.lazy(() => lazyWrapper(() => import('../routes/activateEmail')) as any);
const ForgotPage = React.lazy(() => lazyWrapper(() => import('../routes/forgot')) as any);

const SignUpPage = React.lazy(() => lazyWrapper(() => import('../routes/signup')) as any);
const PortalPage = React.lazy(() => lazyWrapper(() => import('../routes/portal')) as any);
const ChangePhonePage = React.lazy(() => lazyWrapper(() => import('../routes/changePhone')) as any);
const JoinGroupPage = React.lazy(() => lazyWrapper(() => import('../routes/joinGroup')) as any);

//= ============================================//
//= ============== RootContainer ===============//
//= ============================================//

interface RootContainerProps {}

const RootContainer: FC<RootContainerProps> = (): ReactElement => {
    const classes = useStyles({});
    const parsed = queryString.parse(window.location.search);

    React.useEffect(() => {
        const { referrer } = document;
        if (
            referrer &&
            referrer.includes(configs.envConfig.currentEnv.domain) &&
            referrer.match(/:\/\/(.[^/]+)/)[1].endsWith(configs.envConfig.currentEnv.domain)
        ) {
            console.log('saving referrer');
            asyncStorageApi.persistRedirect(referrer);
        }
    }, []);

    //= ===============================================//
    //= ============== PARSED & Actions ===============//
    //= ===============================================//
    if (parsed.statusBarHeight) {
        asyncStorageApi.persistDataNoLink(KEY_WV_STATUS_BAR_HEIGHT, parsed.statusBarHeight);
    }

    if (parsed.mobileVersion) {
        asyncStorageApi.persistDataNoLink(KEY_WV_MOBILE_VERSION, parsed.mobileVersion);
    }

    if (parsed.app || parsed.orchestre) {
        asyncStorageApi.persistDataNoLink(KEY_WV_APP_FROM, parsed.app ?? parsed.orchestre);
    } else {
        asyncStorageApi.clearDataNoLink(KEY_WV_APP_FROM);
    }

    if (parsed.action) {
        saveDeepLinkActions(parsed as unknown as DeepLink);
    }

    // const router = createBrowserRouter([
    //     {
    //         element: <Layout />,
    //         children: [
    //             { path: paths.home, element: <HomePage /> },
    //             { path: paths.concur, element: <ConcurPage /> },
    //             { path: paths.test, element: <TestPage /> },
    //             { path: paths.captcha, element: <CaptchaPage /> },
    //             /* { path: paths.detect, element: <DetectPage />}, */
    //             { path: paths.otp, element: <OtpPage /> },
    //             { path: paths.validatePhone, element: <VerifyPhonePage /> },
    //             { path: paths.askPhone, element: <AskPhonePage /> },
    //             { path: paths.password, element: <PasswordPage /> },
    //             { path: paths.setPassword, element: <SetPasswordPage /> },
    //             { path: paths.activate, element: <ActivatePage /> },
    //             { path: paths.emailActivate, element: <ActivateEmailPage /> },
    //             { path: paths.forgot, element: <ForgotPage /> },
    //             { path: paths.forgotPassword, element: <SetPasswordPage /> },
    //             ...(process.env.REACT_APP_ACCESS_SIGNUP_HIDDEN
    //                 ? []
    //                 : [{ path: paths.signup, element: <SignUpPage /> }]),
    //             { path: paths.portal, element: <PortalPage /> },
    //             { path: paths.changePhone, element: <ChangePhonePage /> },
    //             { path: paths.joinGroup, element: <JoinGroupPage /> },

    //             // All the rest
    //             { path: '*', element: <Navigate to={paths.home} replace /> },
    //         ],
    //     },
    // ]);

    return (
        <div className={classes.rootContainer}>
            <React.Suspense fallback={<div>...Loading</div>}>
                <Router>
                    <ResponsiveCenterContainer>
                        <Paper className={classes.rootPaper}>
                            <ToastContainer />
                            <LogoView />
                            <Routes>
                                <Route path={paths.home} element={<HomePage />} />
                                <Route path={paths.concur} element={<ConcurPage />} />
                                <Route path={paths.test} element={<TestPage />} />
                                <Route path={paths.captcha} element={<CaptchaPage />} />
                                <Route path={paths.otp} element={<OtpPage />} />
                                <Route path={paths.validatePhone} element={<VerifyPhonePage />} />
                                <Route path={paths.askPhone} element={<AskPhonePage />} />
                                <Route path={paths.password} element={<PasswordPage />} />
                                <Route path={paths.setPassword} element={<SetPasswordPage />} />
                                <Route path={paths.activate} element={<ActivatePage />} />
                                <Route path={paths.depreciatedEmailActivate} element={<ActivateEmailPage />} />
                                <Route path={paths.emailActivate} element={<ActivateEmailPage />} />
                                <Route path={paths.forgot} element={<ForgotPage />} />
                                <Route path={paths.forgotPassword} element={<SetPasswordPage />} />
                                {!process.env.REACT_APP_ACCESS_SIGNUP_HIDDEN && (
                                    <Route path={paths.signup} element={<SignUpPage />} />
                                )}
                                <Route path={paths.portal} element={<PortalPage />} />
                                <Route path={paths.changePhone} element={<ChangePhonePage />} />
                                <Route path={paths.joinGroup} element={<JoinGroupPage />} />
                                <Route path={'*'} element={<Navigate to={paths.home} replace />} />
                            </Routes>
                        </Paper>
                    </ResponsiveCenterContainer>
                </Router>
            </React.Suspense>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh',
            backgroundColor: 'white',
        },
        rootPaper: {
            display: 'flex',
            margin: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.colors.background.appBar,
            flexDirection: 'column',
            flex: 1,
        },
    }),
);

export default RootContainer;
