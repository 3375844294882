const company = process.env.REACT_APP_TITLE || 'LeCab';

export default {
    profile: {
        profile: 'Profil',
        editProfile: 'Éditer mon profil',
        changePwd: 'Modifier mon mot de passe',
        logout: 'Déconnexion',
        editInfos: 'Modifier mes informations',
        editPassword: 'Modifier mon mot de passe',
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Adresse e-mail',
        phone: 'Téléphone',
        emailPro: 'Email professionnel',
        company: 'Compagnie',
        group: 'Groupe',
        confirmButton: 'Confirmer',
        cancelButton: 'Annuler',
        password: 'Mot de passe',
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmer votre mot de passe',
    },
    errors: {
        canNotBeEmpty: 'Ce champ ne peut pas être vide',
        invalidFormat: 'Format non valide',
        captcha: 'Une erreur est survenue à la validation. Veuillez réessayer.',
    },
    common: {
        signup: "S'inscrire",
        signingUp: 'Inscription',
        connection: 'Se connecter',
        connect: 'Connexion',
        disconnect: 'Déconnexion',
        confirm: 'Confirmer',
        add: 'Ajouter',
        back: 'Retour',
        next: 'Suivant',
        continue: 'Continuer',
        edit: 'Modifier',
        delete: 'Supprimer',
        reset: 'Réinitialiser',
        search: 'Recherche',
        noResult: 'Aucun résultat',
        perso: 'Perso',
        pro: 'Pro',
        dot: '•',
        rideBetween: 'Courses entre',
        displayRidesBetween: 'Afficher les courses entre ces dates',
        starDateLabel: 'Début',
        endDateLabel: 'Fin',
        resetFilter: 'Réinitialiser les filtres',
        clear: 'Effacer',
        cancel: 'Annuler',
        cancelled: 'Annulée',
        save: 'Sauvegarder',
        skip: 'Passer',
        na: 'N/D',
        more: 'Voir plus',
        blabla: 'Lorem ipsum',
        forgot: 'Mot de passe oublié ?',
    },
    time: {
        format: 'dd/MM/yyyy à HH:mm',
        lettreFormat: 'ddd DD MMM à HH:mm',
        isoFormat: 'yyyy-MM-DD[T]HH:mm',
        h: 'h',
        m: 'm',
        s: 's',
        hours: 'heures',
        minutes: 'minutes',
        seconds: 'secondes',
    },
    concur: {
        accessDenied: 'Adresse e-mail ou mot de passe incorrects',
        signinWithLeCab: `Connectez-vous avec ${company} pour lier votre compte SAP Concur`,
        loginSuccessful: 'Connexion réussie',
        concurLinkSuccessful: 'Votre compte SAP Concur a été lié avec succès',
        concurLinkError:
            "Une erreur s'est produite lors de la liaison de votre compte. Veuillez réessayer ultérieurement.",
        termsAndConditions: 'Conditions Générales de Vente',
        privacyPolicy: 'Politique de Confidentialité',
        contactUs: 'Nous Contacter',
    },
    login: {
        title: {
            signup: 'Créer votre profil',
            portal: 'Bienvenue sur le portail',
            otp: 'Recevoir votre code',
            askOtp: 'Validez votre numéro de téléphone',
            changePhone: 'Changer de numéro',
            missingPhone: 'Ajouter un numéro de téléphone',
            password: 'Mot de passe',
            setPassword: 'Sécurisez votre compte',
            activate: 'Consulter votre boîte de réception',
            welcomeBack: 'Ravi de vous revoir',
        },
        placeholder: {
            phone: 'Téléphone',
            email: 'E-mail',
            emailPhone: 'E-mail ou numéro de téléphone',
            otp: 'Code ou mot de passe',
            otpOnly: 'Code',
            password: 'Mot de passe',
            createPassword: 'Créer votre mot de passe',
            confirmPassword: 'Confirmer votre mot de passe',
        },
        link: {
            resendOtp: 'Renvoyer le code',
            skip: 'Passer cette fois',
        },
        helper: {
            needSignup: `Nouveau sur ${company} ?`,
            needSignin: `Vous avez déjà un compte ${company} ?`,
            needEmail: "Votre téléphone n'est pas validé, veuillez d'abord vous connecter avec votre e-mail.",
            didNotReceive: "Vous n'avez rien reçu ?",
            alreadyActivated: 'Compte déjà activé ?',
            passwordWithMail: 'Saisissez votre mot de passe correspondant à l’e-mail <b>{{mail}}</b>.',
            passwordWithPhone: 'Saisissez votre mot de passe correspondant au numéro <b>{{mail}}</b>.',
            otpCode:
                'Saisissez le code à 4 chiffres reçu par SMS au <b>** ** ** {{phone}}</b> ou <b>votre mot de passe</b>.',
            otpOnly: 'Saisissez le code à 4 chiffres reçu par SMS au <b>** ** ** {{phone}}</b>',
            askOtp: 'Validez votre téléphone <b>** ** ** {{phone}}</b> pour pouvoir contacter votre chauffeur et recevoir les SMS de suivi de course.',
            changePhone: 'Complétez votre profil avec un numéro de téléphone valide.',
            missingPhone: 'Complétez votre profil avec un numéro de téléphone',
            activate:
                "Pour activer votre compte, veuillez cliquer sur le lien que nous venons de vous envoyer à l'adresse <b>{{mail}}</b>.",
            forgot: 'Saisissez votre adresse e-mail, nous vous enverrons un lien pour réinitialiser votre mot de passe.',
        },
        action: {
            resetPassword: 'Réinitialiser le mot de passe',
            resendMail: "Renvoyer l'e-mail",
        },
        password: {
            minimumCharacter: '{{min}} caractères',
            rule: 'Majuscule, minuscule, chiffre et caractère spécial ({{special}})',
        },
        error: {
            password: 'Mot de passe incorrect',
            passwordDifferent: 'Les mots de passe doivent être identiques',
            passwordRules: 'Le mot de passe ne respecte pas les règles',
            otpCode: 'Code de vérification incorrect',
            default: "Une erreur s'est produite, veuillez réessayer ultérieurement",
            domainError: 'Mauvais nom de domaine.',
            validationError: "Votre email n'a pas pu être activé, veuillez réessayer ultérieurement",
            joinGroupError: "Erreur lors de l'ajout au groupe, veuillez réessayer plus tard",
            passwordExpiredError: 'Votre lien de réinitialisation du mot de passe est expiré. Veuillez réessayer',
            inviteError:
                "Votre lien d'invitation n'est plus valable, veuillez contacter l'administrateur de votre entreprise",
            phoneHomeError:
                'Vous devez renseigner un numéro de téléphone portable afin de pouvoir recevoir les SMS de suivi de votre course ainsi que joindre votre chauffeur.',
            phoneInvalidError: 'Numéro invalide, veuillez corriger votre numéro.',
            expired: 'Temps expiré, veuillez saisir à nouveau votre email.',
        },
        info: {
            forgot: `Si un compte ${company} existe pour <b>{{email}}</b>, un e-mail vous a été envoyé avec des instructions supplémentaires pour réinitialiser votre mot de passe.`,
        },
        official: {
            cgu: "En cochant cette case, vous reconnaissez avoir pris connaissance et accepté nos <a href='https://lecab.fr/conditions-generales-de-vente/'>Conditions générales de vente</a>. *",
            marketing: `J'accepte de recevoir des e-mails promotionnels de ${company}`,
        },
        external: {
            popup: {
                title: 'Marcel by LeCab',
                details: {
                    title: 'Marcel et LeCab s’allient pour vous proposer :',
                    info1: 'Un service premium',
                    info2: 'Des chauffeurs ponctuels',
                    info3: 'Des forfaits aéroports',
                    info4: '5€ offerts toutes les 3 courses',
                },
                infoTitle: 'Téléchargez l’application LeCab',
                infoDescription:
                    'Vous êtes actuellement sur l’application de transition “Marcel by LeCab”. Téléchargez dès maintenant l’application officielle LeCab.',
                webTitle: 'N’oubliez pas de modifier vos favoris.',
                webDescription:
                    'Si vous aviez l’habitude d’accéder à votre site depuis vos favoris, n’oubliez pas de mettre à jour son lien.',
                download: 'Télécharger Lecab',
                later: 'Plus tard',
            },
            info: {
                title: 'Vos identifiants restent inchangés',
                description: 'Pour vous connecter, utilisez l’adresse email et le mot de passe de votre compte Marcel.',
                web: 'Marcel devient LeCab.',
            },
        },
    },
};
